import http, { AUTH_ROUTES } from "../service/api";

export const getUserProfile = async (setState, account_type) => {
  let loading = true;
  try {
    loading = true;
    const data = await http.get(AUTH_ROUTES.GET_ACCOUNT);
    console.log("data>>>>>>!!!!!", data?.data?.data);
    loading = false;

    setState &&
      setState((prev) => ({
        ...prev,
        loading,
        userInfo: data?.data?.data,
      }));

    return data?.data?.data;
  } catch (e) {
    console.log(e, "here>>>>>>");
    loading = false;
    // errorNotifier(e.response?.data?.result?.message);
  }
};

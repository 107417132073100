import axios from "axios";

export * from "./routes.constants";

export const baseURL = "https://f8.codester.dev/web/api";
export const photoBaseURL = "https://f8.codester.dev/storage/";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Headers": "Content-Type",
  },
});
console.log("ee");

const addTokenToRequest = async (req) => {
  const token = sessionStorage.getItem("#f8WEB#");
  console.log(token);

  // if (token) {
  req.headers.Authorization = `Bearer ${token}`;
  return req;
  // } else {
  // return "";
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;

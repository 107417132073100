import { errorNotifier, successNotifier } from "../../..";
import http, { AUTH_ROUTES } from "../../../service/api";


export const getGroup = async (setGroup, acct_type, id, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_GROUP(acct_type, id));
    console.log("data", data);
    setGroup(data?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e?.response);
    // errorNotifier(e.response?.data?.data?.message);
  }
};

export const getChats = async (setChats, acct_type, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ALL_CHATS(acct_type));
    console.log("data", data);
    setChats(data?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e?.response);
    // errorNotifier(e.response?.data?.data?.message);
  }
};

export const sendMessage = async (payload, acct_type, setLoading) => {
  try {
    const data = await http.post(AUTH_ROUTES.SEND_MESSAGE(acct_type), payload);
    console.log("data", data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e?.response);
    errorNotifier(e.response?.data?.data?.message);
  }
};
export const VideoCall= async (from,to,peerid,setCalling) =>{
  try {
    await http.get(`https://f8.codester.dev/web/api/video/${from}/${to}/${peerid}`)
    .then((req) => {
      console.log(req.data.res);
    }, (error) => {
      console.log(error);
    });
  } catch (e) {
     alert("Server issue");
  console.log(e?.response);
   setCalling(false);
  }
};

export const GetVideoCall= async (to,setFrom,setTo,setCallPeerid,setCallModal) =>{
  try {
  await http.get(`https://f8.codester.dev/web/api/getvideo/${to}`)
  .then((req)=>{
    setFrom(req.data.from);
    setTo(req.data.to);
    setCallPeerid(req.data.peerid);
    setCallModal(true);
  });
       
  } catch (e) {
    //alert("Server issue");
  console.log(e?.response);
  //setCalling(false);
  }
};
export const EndVideoCall= async (from) =>{
  try {
  await http.get(`https://f8.codester.dev/web/api/endvideo/${from}`)
  .then((req)=>{
    // setCallModal(false);
    
  });
       
  } catch (e) {
    //alert("Server issue");
  console.log(e?.response);
  //setCalling(false);
  }
};
import "./App.css";
import { lazy, Suspense,useEffect,useState } from "react";
import { UserProfileContextProvider } from "./context/UserContext";
import "firebase/compat/firestore";
import { useGetState } from "./context/useGetState";
import { ChatContextProvider } from "./context/ChatContext";
import {GetCallContext} from "./context/GetCallContext"
 //import VideoCallNotify from './lib/VideoCallNotify';
import { GetVideoCall } from "./pages/messaging/service";
const UnAuthenticatedRoutes = lazy(() =>
  import("./components/UnAuthenticatedRoutes")
);
const AuthenticatedRoutes = lazy(() =>
  import("./components/AuthenticatedRoutes")
);
 
function App() {
  const { state, firebaseUser } = useGetState() || {};
  const [to,setTo]=useState("");
  const [from,setFrom]=useState("");
  const [CallPeerid,setCallPeerid]=useState("");
  const [Accept,setAccept]=useState(false);
  const [CallModal,setCallModal]=useState(false);
 
  let token =
    sessionStorage.getItem("#f8WEB#") &&
    sessionStorage.getItem("#f8WEB-firebase-success#");
    useEffect(()=>{
      
    },[])
  return (
    <div className="App">
      <UserProfileContextProvider>
        <ChatContextProvider>
          <GetCallContext.Provider value={{to,setTo,from,setFrom,CallPeerid,setCallPeerid,Accept,setAccept}}>
          <Suspense fallback={<p></p>}>
            {token ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}
          </Suspense>
          </GetCallContext.Provider>
        </ChatContextProvider>
      </UserProfileContextProvider>
    </div>
  );
}

export default App;

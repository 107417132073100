// Import the functions you need from the SDKs you need
import dayjs from "dayjs";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { generateAlphabethId } from "../utils/indes";
const AWS = require("aws-sdk");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage();
const auth = getAuth();

async function loginWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();

    const { user } = await signInWithPopup(auth, provider);

    return { uid: user.uid, displayName: user.displayName };
  } catch (error) {
    if (error.code !== "auth/cancelled-popup-request") {
      console.error(error);
    }

    return null;
  }
}

async function sendMessage(roomId, user, msg, img, setImg) {
  // const timestamp = { seconds: dayjs.utc()?.unix() };
  const timestamp = serverTimestamp();

  try {
    if (img) {
      let wasabiEndpoint = new AWS.Endpoint("s3.us-central-1.wasabisys.com");
      let s3 = new AWS.S3({
        endpoint: wasabiEndpoint,
        accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_WASABI_SECRET_KEY,
      });

      var params = {
        Bucket: process.env.REACT_APP_WASABI_BUCKET_NAME,
        Key: `${roomId}-${generateAlphabethId(12)}`,
        Body: img,
      };

      var options = {
        partSize: 10 * 1024 * 1024, // 10 MB
        queueSize: 10,
      };

      s3.upload(params, options, async (err, data) => {
        if (!err) {
          await addDoc(collection(db, "trading-group", roomId, "messages"), {
            roomId,
            sender: user,
            userId: user?.id,
            displayName: user?.name,
            text: msg?.trim(),
            img: data?.Location,
            timestamp,

            // timestamp: Timestamp.now(),
          });

          await addDoc(collection(db, "my-groups"), {
            roomId,
            // groups: [],
            sender: user,
            userId: user?.id,
            displayName: user?.name,
            text: msg?.trim(),
            img: data?.Location,
            timestamp,
          });
          setImg("");
          console.log(data, "file success"); // successful response

          //send last group message
          const lastMessageIdExist = await getDoc(
            doc(db, "group-last-message", roomId)
          );

          if (lastMessageIdExist?.exists()) {
            await updateDoc(doc(db, "group-last-message", roomId), {
              lastMessage: {
                img: true,
                time: timestamp.seconds,
                sender: user?.username,
              },
              roomId,
            });
          } else {
            await setDoc(doc(db, "group-last-message", roomId), {
              lastMessage: {
                img: true,
                time: timestamp.seconds,
                sender: user?.username,
              },
              roomId,
            });
          }
        } else {
          console.log(err); // an error occurred
        }
      });
    } else {
      await addDoc(collection(db, "trading-group", roomId, "messages"), {
        roomId,
        sender: user,
        userId: user?.id,
        displayName: user?.name,
        text: msg.trim(),
        timestamp,

        // timestamp: Timestamp.now(),
      });

      await addDoc(collection(db, "my-groups"), {
        roomId,
        // groups: [],
        sender: user,
        userId: user?.id,
        displayName: user?.name,
        text: msg.trim(),
        timestamp,
      });

      //send last group message
      const lastMessageIdExist = await getDoc(
        doc(db, "group-last-message", roomId)
      );

      if (lastMessageIdExist?.exists()) {
        await updateDoc(doc(db, "group-last-message", roomId), {
          lastMessage: {
            text: msg.trim(),
            time: timestamp,
            sender: user?.username,
          },
          roomId,
        });
      } else {
        await setDoc(doc(db, "group-last-message", roomId), {
          lastMessage: {
            text: msg.trim(),
            time: timestamp,
            sender: user?.username,
          },
          roomId,
        });
      }
    }

    // stop showing typing status
    await updateDoc(doc(db, "typing-status", roomId), {
      typing: false,
    });

    //sent group notification status
    const newMessageNotificationExist = await getDoc(
      doc(db, "new-group-message-notification", roomId)
    );

    if (newMessageNotificationExist?.exists()) {
      await updateDoc(doc(db, "new-group-message-notification", roomId), {
        sender: user,
        time: timestamp,
      });
    } else {
      await setDoc(doc(db, "new-group-message-notification", roomId), {
        sender: user,
        time: timestamp,
      });
    }

    // console.log("MY ROOM DATA", myRoomData);
  } catch (error) {
    console.error(error?.response);
  }
}

async function getMessages(roomId, callback) {
  // const colRef = collection(db, "trading-group");
  // const docsSnap = await getDocs(colRef);
  // console.log("GOOGLEcolRef", docsSnap);

  return onSnapshot(
    query(
      collection(db, "trading-group", roomId, "messages"),
      orderBy("timestamp", "asc")
    ),
    (querySnapshot) => {
      // console.log("all SNAPSHOT chats", querySnapshot);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(messages);
    }
  );
}

//PRIVATE MESSAGE
const sendPrivateMessage = async (sender, receiver, msg) => {
  try {
    if (sender && receiver) {
      await addDoc(
        collection(
          db,
          "private-chat", // Collection
          `${sender.id}`, // sender doc id
          "chatUsers", //Collection
          `${receiver.id}`, //reciever doc id
          "messages" // Collection
        ),
        {
          sender: sender,
          userId: sender.id,
          userName: sender?.username,
          displayName: sender.name,
          recipient: receiver,
          text: msg.trim(),
          timestamp: dayjs().unix(),
        }
      );

      // await addDoc(
      //   collection(
      //     db,
      //     "users", //Collection
      //     recieverData.userId, // receiver doc id
      //     "chatUsers", //Collection
      //     user.uid, //sender doc id
      //     "messages" //Collection
      //   ),
      //   {
      //     username: user.displayName,
      //     messageUserId: user.uid,
      //     message: chatMessage,
      //     timestamp: new Date(),
      //   }
      // );
    }
  } catch (error) {
    console.log(error);
  }
};

const getPrivateMessage = (sender, receiver, setAllMessages) => {
  if (receiver) {
    const unsub = onSnapshot(
      query(
        collection(
          db,
          "private-chat",
          `${sender?.id}`,
          "chatUsers",
          `${receiver?.id}`,
          "messages"
        ),
        orderBy("timestamp", "asc")
      ),
      (snapshot) => {
        console.log("all PRIVATE SNAPSHOT chats", snapshot);
        setAllMessages(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            messages: doc.data(),
          }))
        );
      }
    );
    return unsub;
  }
};

export {
  loginWithGoogle,
  sendMessage,
  getMessages,
  sendPrivateMessage,
  getPrivateMessage,
  auth,
};

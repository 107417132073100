import { createContext, useReducer } from "react";
import { useGetState } from "./useGetState";

export const ChatContext = createContext({});

export const ChatContextProvider = ({ children }) => {
  const { state: values } = useGetState();
  const { userInfo } = values;
  const userId = userInfo?.firebase_token;
  const INITIAL_STATE = {
    chatId: null,
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId:
            userId > action?.payload?.userId
              ? userId + action?.payload?.userId
              : action?.payload?.userId + userId,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ dispatch, data: state }}>
      {children}
    </ChatContext.Provider>
  );
};

let acc_type1 = sessionStorage.getItem("acc_type") || "user";
export const AUTH_ROUTES = {
  // AUTH
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_EMAIL: "/verify-email",
  PERSONAL_INFORMATION: "/account?personal-information-1=true",
  CREATE_ACCOUNT: "/account?personal-information-2=true",
  EMAIL_VERIFICATION: "/email/verification-notification",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  RESET_PASSWORD: "/reset-password",
  LOGOUT: "/logout",
  LOGIN_WITH_FACEBOOK: "/auth/facebook/callback",
  LOGIN_WITH_GOOGLE: "/auth/google/callback",

  // ACCOUNT AND PROFILE
  GET_USER: "/user/account",
  UPDATE_PROFILE: (acct_type = acc_type1) =>
    `/${acc_type1}/account?update-profile=true`,
  UPDATE_PASSWORD: "/user/account?change-password=true",
  UPDATE_EMAIL: "/user/account?change-email=true",
  VERIFY_CHANGED_EMAIL: "/verify-email?changed-email=true",
  CHANGED_EMAIL_VERIFICATION:
    "/phone-no/verification-notification?changed-phone-no=true",

  // DISCOVER
  GET_RANDOM_TRADERS_OR_USERS: (users_type = acc_type1) =>
    `/${acc_type1}/trader`,

  // MATCHING
  FOLLOW: (acct_type = acc_type1) => `/${acc_type1}/matching`,
  UNFOLLOW: (acct_type = acc_type1) => `/${acc_type1}/matching?unfollow=true`,

  // MESSAGING
  GET_ALL_CHATS: (acct_type = acc_type1) => `/${acc_type1}/chat`,
  SEND_MESSAGE: (acct_type = acc_type1) => `/${acc_type1}/chat`,

  // STORIES
  POST_STORIES: (acct_type = acc_type1) => `/${acc_type1}/story`,
  GET_FOLLOWING_STORIES: (acct_type = acc_type1) =>
    `/${acc_type1}/story?following_stories`,
  DISPLAY_STORY: (acct_type, storyId) => `/${acc_type1}/story/${storyId}`,
  GET_USER_STORIES: (acct_type) => `/${acc_type1}/story`,
  GET_SPECIFIC_USER_STORIES: (acct_type = acc_type1, userId) =>
    `/${acc_type1}/story?user_stories&user_id=${userId}`,

  // TRADERS
  GET_ACCOUNT: `/${acc_type1}/account`,
  GET_ALL_TRADERS: "/user/trader",
  TRADERS_NOT_FOLLOWED: "/user/trader?not_followed=true",
  NOT_INTERESTED: `/${acc_type1}/uninterested`,

  // TRADING GROUPS
  JOIN_GROUP: (acct_type = acc_type1, invite_link) =>
    `/${acc_type1}/group-chat?invite_link=${invite_link}`,
  CHECKOUT: (accountType = acc_type1) =>
    `/${acc_type1}/group-chat?checkout=true`,
  GET_ALL_GROUPS: (account_type = acc_type1) =>
    `/${acc_type1}/group-chat?group-chats=true`,
  CREATE_GROUPS: (account_type = acc_type1) => `/${acc_type1}/group-chat`,
  UPDATE_GROUP: (account_type = acc_type1, groupId) =>
    `/${acc_type1}/group-chat/${groupId}?update=true`,
  GET_GROUP: (acct_type = acc_type1, id) => `/${acc_type1}/group-chat/${id}`,
  GET_USER_GROUPS: (acct_type = acc_type1) =>
    `/${acc_type1}/group-chat?user-group-chats=true`,
  SEND_GROUP_MESSAGE: (acct_type = acc_type1) =>
    `/${acc_type1}/group-chat-message`,

  SEARCH_USER: (search, acc_type = acc_type1) =>
    `${acc_type1}/all-user?q=${search}`,
  GET_PROFILE_DETAILS: (id, acct_type = acc_type1) =>
    `/${acc_type1}/all-user/${id}`,
  GET_PROFILE_DETAILS_WITHOUT_AUTH: (id) => `/all-user/${id}`,
  UPDATE_ABOUT: (acc_type = acc_type1) =>
    `/${acc_type1}/account?update_about=true`,
  UPDATE_COVER_IMAGE: `/${acc_type1}/account?update_cover=true`,

  UPDATE_SOCIAL_MEDIA: `/${acc_type1}/account?update_social_media=true`,
  USER_VERIFICATION: `/${acc_type1}/account?user_verification=true`,
  UPLOAD_POST: `/${acc_type1}/post`,
  UPDATE_POST: (id) => `/${acc_type1}/post/${id}`,
  GET_POSTS: `/${acc_type1}/post`,
  SEND_FIREBASE_ID: `/account?update_firebase_token=true`,
  GET_NOTIFICATIONS: `/${acc_type1}/notification`,
  GOOGLE_AUTH: "/google/oauth",
};

import { onAuthStateChanged } from "@firebase/auth";
import { createContext, useEffect, useState } from "react";
import FullPageLoader from "../components/full-page-loader";
import { auth } from "../service/firebase";
import { getUserProfile } from "./getUserProfile";
import { useGetState } from "./useGetState";
// import { useSelector } from "react-redux";
// import { getUserProfile } from "./getUserProfile";
// import { AuthContext } from "./authContext";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({
    signin: true,
    forgotPassword: false,
    resetPassword: false,
  });
  console.log("THE STATE55000,", state);
  const [firebaseUser, setFirebaseUser] = useState({});
  const token = sessionStorage.getItem("#f8WEB#");
  const { state: globalState } = useGetState() || {};
  console.log(globalState, "rr");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (!token) {
      setLoading(false);
      return;
    }
    dispatch({ ready: false });

    const getUsersAndStore = async () => {
      // await onAuthStateChanged(auth, (user) => {
      //   setFirebaseUser(user);
      //   dispatch({ user: user });
      // });
      await getUserProfile(
        setState,
        sessionStorage.getItem("acc_type") || "trader"
      );
      setLoading(false);
    };

    getUsersAndStore();
    // dispatch({ ready: true });
  }, [token, globalState]);

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     // sessionStorage.setItem("#f8-login", true);
  //     dispatch({  });
  //     // console.log("FIREBASE USER", user);
  //   });
  // }, [token]);

  return (
    <UserProfileContext.Provider value={{ dispatch, state, firebaseUser }}>
      {loading ? <FullPageLoader /> : children}
    </UserProfileContext.Provider>
  );
};
